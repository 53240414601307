<template>
  <div style="position:relative">
    <div style="position:absolute;top:7px;z-index: 99;right: 1px;">
      <qzf-video vid="4467be537469aaf52da33b0ac920726b_4"></qzf-video>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" hei>

      <el-tab-pane label="科目余额表" name="1">
        <subjectBalance ref="subjectBalance" @success="tiaozhuan" ></subjectBalance>
      </el-tab-pane>
      <el-tab-pane label="核算项目余额表" name="2">
        <fzhsSubjectBalance ref="fzhsSubjectBalance"></fzhsSubjectBalance>
      </el-tab-pane>
      <el-tab-pane label="凭证汇总" name="3">
        <proofCollect ref="proofCollect" ></proofCollect>
      </el-tab-pane>
      <el-tab-pane label="总账" name="4">
        <generalLedger ref="generalLedger" ></generalLedger>
      </el-tab-pane>
      <el-tab-pane label="明细账" name="5">
        <detailAccount ref="detailAccount" ></detailAccount>
      </el-tab-pane>
      <el-tab-pane label="核算项目明细账" name="6">
        <fzhsDetailAccount ref="fzhsDetailAccount" ></fzhsDetailAccount>
      </el-tab-pane>
      <el-tab-pane label="多栏账" name="7">
        <multicolumnAccount ref="multicolumnAccount" ></multicolumnAccount>
      </el-tab-pane>
      <el-tab-pane label="日记账" name="8">
        <dayBook ref="dayBook" ></dayBook>
      </el-tab-pane>
    </el-tabs>
    <!-- <qzf-tabs v-model:value="value">
      <qzf-tabs-item label="科目余额表" name="1" :cache="false">
        <subjectBalance ref="subjectBalance"></subjectBalance>
      </qzf-tabs-item>
      <qzf-tabs-item label="核算项目余额表" name="2" :cache="false">
        <fzhsSubjectBalance ref="fzhsSubjectBalance"></fzhsSubjectBalance>
      </qzf-tabs-item>
      <qzf-tabs-item label="凭证汇总" name="3" :cache="false">
        <proofCollect ref="proofCollect"></proofCollect>
      </qzf-tabs-item>
      <qzf-tabs-item label="总账" name="4" :cache="false">
        <generalLedger ref="generalLedger"></generalLedger>
      </qzf-tabs-item>
      <qzf-tabs-item label="明细账" name="5" :cache="false">
        <detailAccount ref="detailAccount"></detailAccount>
      </qzf-tabs-item>
      <qzf-tabs-item label="核算项目明细账" name="6" :cache="false">
        <fzhsDetailAccount ref="fzhsDetailAccount"></fzhsDetailAccount>
      </qzf-tabs-item>
      <qzf-tabs-item label="多栏账" name="7" :cache="false">
        <multicolumnAccount ref="multicolumnAccount"></multicolumnAccount>
      </qzf-tabs-item>
      <qzf-tabs-item label="日记账" name="8" :cache="false">
        <dayBook ref="dayBook"></dayBook>
      </qzf-tabs-item>
    </qzf-tabs> -->
  </div>
</template>

<script>
import subjectBalance from "./components/subjectBalance.vue"
import fzhsSubjectBalance from "./components/fzhsSubjectBalance.vue"
import proofCollect from "./components/proofCollect.vue"
import detailAccount from "./components/detailAccount.vue"
import generalLedger from "./components/generalLedger.vue"
import fzhsDetailAccount from "./components/fzhsDetailAccount.vue"
import multicolumnAccount from "./components/multicolumnAccount.vue"
import dayBook from "./components/dayBook.vue"

export default {
  name: "accountBook",
  components:{
    subjectBalance,
    fzhsSubjectBalance,
    proofCollect,
    detailAccount,
    generalLedger,
    fzhsDetailAccount,
    multicolumnAccount,
    dayBook
  },
  data() {
    return{
      activeName:'1'
    }
  },
  methods:{
    handleClick(tab,e){
      console.log(tab);
      console.log(this.value);
      if(tab.props.label == '科目余额表'){
        this.$refs.subjectBalance.getList()
      }else if(tab.props.label == '核算项目余额表'){
        // this.$refs.fzhsSubjectBalance.getList()
      }else if(tab.props.label == '凭证汇总'){
        this.$refs.proofCollect.getList()
      }else if(tab.props.label == '总账'){
        this.$refs.generalLedger.getList()
      }else if(tab.props.label == '明细账'){
        this.$refs.detailAccount.getList(e)
      }else if(tab.props.label == '核算项目明细账'){
        this.$refs.fzhsDetailAccount.getList()
      }else if(tab.props.label == '多栏账'){
        this.$refs.multicolumnAccount.getList()
      }else if(tab.props.label == '日记账'){
        // this.$refs.dayBook.getList()
      }
    },
    tiaozhuan(e){
      let param = {
        props:{
          label:'明细账',
        },
      }
      this.activeName = '5'
      this.handleClick(param,e)
    }
  }
}
</script>

<style>

</style>