<template>
  <div class="app-container">
    <div class="left_box">
      <!-- 筛选 -->
      <search @success="getList">
        <el-form style="margin:11px 0px 0px -26px;height:160px;width:430px" class="styleForm">
          <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
            <qzf-period v-model:period="listQuery.period" hidden="true"></qzf-period>
          </el-form-item>
          <el-form-item label="辅助类别 ：" :label-width="formLabelWidth"> 
            <fzhsSubjectList v-model:fzhsId="listQuery.fzhsId" @initSuccess="getList"></fzhsSubjectList>
          </el-form-item>
          <el-form-item label="会计期间 ：" :label-width="formLabelWidth">  
            <qzf-period v-model:period="listQuery.beginTime" hidden="true"></qzf-period>
            <span>-</span>
            <qzf-period v-model:period="listQuery.endTime" hidden="true"></qzf-period>
          </el-form-item>
        </el-form>
      </search>
    </div>

    <el-row>
      <el-col :span="5">
        <div class="center_main">
          <el-input v-model="filterText" placeholder="请输入关键字" style="width:100%;margin-bottom: 10px;" size="small"></el-input>
          <el-tree ref="tree" :filter-node-method="filterNode" :data="data" :props="defaultProps" @node-click="handleNodeClick" node-key="id" style="width:100%;height:80%;overflow:scroll"></el-tree>
        </div>
      </el-col>
      <el-col :span="19">
        <div>
          <div style="margin-left:26px;margin-bottom:10px;color:$color-main;font-weight:600;font-size:15px">辅助核算项目:{{fzhsName}} <span v-if="this.fzhsSubjectName != ''">>></span> {{fzhsSubjectName}}</div>
          <el-table stripe :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column label="日期" align="center" min-width="100">
            <template  #default="scope">
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="科目" align="center" min-width="200">
            <template #default="scope">
              <span>{{scope.row.subjectCode}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>

          <el-table-column label="借方" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.inAmount}}</span>
            </template>
          </el-table-column>


          <el-table-column label="贷方" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.outAmount}}</span>
            </template>
          </el-table-column>

          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>

          <el-table-column label="余额" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.endAmount}}</span>
            </template>
          </el-table-column>

        </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import fzhsSubjectList from "./fzhsSubjectList.vue"
import { detailFzhsList , detailFzhs } from "@/api/book.js"
export default {
  name:'fzhsDetailAccount',
  components:{ fzhsSubjectList },
  data() {
    return {
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        fzhsId: 0,
        fzhsItemId: 0,
        subjectId:0,
        rq:[]
      },
      formLabelWidth: '120px',
      contentStyleObj:{}, //高度变化
      filterText: '',
      data:[],
      defaultProps: {
        children: 'items',
        label: 'name',
      },
      list: [],
      fzhsName:'',
      fzhsSubjectName:''
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(220)
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods:{
    getList() {
      detailFzhsList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.data = res.data.data.list
          this.list = []
          this.fzhsName =''
          this.fzhsSubjectName = ''
        }else{
          this.data = []
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleNodeClick(data,node,item){
      if(node.childNodes.length !=0){
        this.fzhsName = node.data.name
        this.fzhsSubjectName = ''
        this.listQuery.fzhsItemId = node.data.id
        this.listQuery.subjectId = 0
      }else{
        this.fzhsName = node.parent.data.name
        this.fzhsSubjectName = node.data.name3
        this.listQuery.fzhsItemId = node.parent.data.id
        this.listQuery.subjectId = node.data.id
      }
      detailFzhs(this.listQuery).then(res=>{
        if(res.data.data.info){
          this.list = res.data.data.info
        }else{
          this.list = []
        }  
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.searchGjz {
  border: 1px solid #ebeef5;
}
.top_btns {
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.center_main{
  width: 100%;
  height:100%;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 20px; 
}
</style>