<template>
  <el-select filterable v-model="id" size="small">
    <el-option
      v-for="item in fzhsOptions"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { fzhsList } from "@/api/subject"
export default {
  props:{
    fzhsId: {
      type: Number,
      default: 0
    },
  },
  created(){
    this.init()
    this.$bus.off("fzhsSubjectListUpdate")
    this.$bus.on("fzhsSubjectListUpdate", (val) => {
      this.init()
    });
  },
  computed: {
    id: {
      get() {
        if(!this.fzhsId){
          return ""
        }
        return this.fzhsId*1
      },
      set(val) {
        this.$emit("update:fzhsId", val * 1)
      }
    }
  },
  data(){
    return{
      fzhsOptions:[],
    }
  },
  methods:{
    init(){
      fzhsList({}).then(res=>{
        this.fzhsOptions = res.data.data.list
        if(this.fzhsOptions && this.fzhsOptions.length !=0){
          this.$emit("update:fzhsId", this.fzhsOptions[0].id * 1)
          this.$emit("initSuccess", this.fzhsOptions[0].id * 1)
        }
      })
    },
  }
}
</script>

<style>

</style>