<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_box">
        <search @success="getList()">
          <el-form style="margin:11px 0px 0px -26px;height:270px;width:430px" class="styleForm">
            <el-form-item label="辅助类别 ：" :label-width="formLabelWidth"> 
              <fzhsSubjectList v-model:fzhsId="listQuery.fzhsId" @initSuccess="getList"></fzhsSubjectList>
            </el-form-item>
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.period" hidden="true"></qzf-period>
            </el-form-item>
            <el-form-item label="核算名称 ：" :label-width="formLabelWidth"> 
              <el-input size="small" v-model="listQuery.name" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="wbstatus" label="显示数量金额"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px">
              <el-checkbox v-model="yearAmount" label="隐藏本年累计发生额"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-dropdown>
          <qzf-button jz="false" button_code="zb_zz_gd" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dya4h" @click="printingA5()" type="success" size="small">
                <el-icon><Printer /></el-icon> <span  >打印</span>
              </qzf-button></el-dropdown-item> -->
              <el-dropdown-item><qzf-button @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span  >导出</span>
              </qzf-button></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <el-table stripe show-summary :summary-method="getSummaries" :height="contentStyleObj" :data="showList" border fit highlight-current-row style="width: 100%;" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>  
      <el-table-column label="核算编码" width="160" align="center">
          <template #default="scope">
            <span class="bianma">{{scope.row.code}}</span>
          </template>
        </el-table-column>

        <el-table-column label="核算名称" min-width="240" align="center">
          <template #default="scope">
            <span class="name_css">{{scope.row.name}}</span>
          </template>
        </el-table-column>

        <el-table-column label="期初余额" align="center">
          <el-table-column  align="center" prop="periodBeginIn" label="借方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginIn}}</span> -->
              <span>{{scope.row.periodBeginIn}}</span>
            </template>
          </el-table-column>
          <el-table-column  align="center" prop="periodBeginOut" label="贷方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{scope.row.periodBeginOut}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="wbstatus" align="center" prop="beginCount" label="数量" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{scope.row.beginCount}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="本期发生额" align="center">
          <el-table-column align="center" prop="periodInt" label="借方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodInt}}</span> -->
              <span>{{scope.row.periodInt}}</span>
            </template>
          </el-table-column>  
          <el-table-column align="center" prop="periodOut" label="贷方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodOut}}</span> -->
              <span>{{scope.row.periodOut}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="wbstatus" align="center" prop="inCount" label="数量借" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{scope.row.inCount}}</span>
            </template>
          </el-table-column>

          <el-table-column v-if="wbstatus" align="center" prop="outCount" label="数量贷" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{scope.row.outCount}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="本年累计发生额" align="center" v-if="yearAmount == false">
          <el-table-column align="center" prop="yearInt" label="借方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.yearInt}}</span> -->
              <span>{{scope.row.yearInt}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="yearOut" label="贷方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.yearOut}}</span> -->
              <span>{{scope.row.yearOut}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="期末余额" align="center">
          <el-table-column align="center" prop="periodEndIn" label="借方" width="120">
            <template #default="scope">
              <span>{{scope.row.periodEndIn}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="periodEndOut" label="贷方" width="120">
            <template #default="scope">
              <span>{{scope.row.periodEndOut}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="wbstatus" align="center" prop="endCount" label="数量" width="120">
            <template #default="scope">
              <span>{{scope.row.periodBeginOut}}</span>
            </template>
          </el-table-column>
        </el-table-column>
    </el-table>

    <exportFile ref="exportFile" name="book_fzhs_subject" :listQuery1="this.listQuery"></exportFile>

  </div>
</template>

<script>
import { fzhsBalanceList  } from "@/api/subject"
import fzhsSubjectList from "./fzhsSubjectList.vue"
import exportFile from "./exportFile.vue"

export default {
  name:'fzhsSubjectBalance',
  components :{ fzhsSubjectList ,exportFile },
  data() {
    return {
      showList:[],
      formLabelWidth: '120px',
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        fzhsId: 0,
        not0: false,
        name: '',
        level: 1,
        bookNames: ["book_fzhs_subject"]
      },
      yearAmount:false,
      wbstatus:false,
      loading:false
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(230)
  },
  methods:{
    getList(){
      this.loading = true
      fzhsBalanceList(this.listQuery).then(res=>{
        this.loading = false
        this.showList = res.data.data.list
      })
    },
    daochu() {
      this.$refs.exportFile.init()
    },
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  .left_box{
    float: left;
    width: 70%;
    margin-bottom: 10px;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.styleForm{
  .el-select{
    margin: 0px;
  }
}
</style>