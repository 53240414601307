<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_box">
        <search @success="getList">
          <el-form style="margin:11px 0px 0px -26px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period" hidden="true"></qzf-period>
            </el-form-item>
            <el-form-item label="会计期间 ：" :label-width="formLabelWidth">   
              <qzf-period v-model:period="listQuery.beginTime" hidden="true"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime" hidden="true"></qzf-period>
            </el-form-item>
            <el-form-item label="科目编码 ：" :label-width="formLabelWidth" >
              <subject-list-all v-model:subjectId="listQuery.subjectId"></subject-list-all>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <!-- <div class="right_box">
        <qzf-export from="进项"></qzf-export>
      </div> -->
      <div class="right_box">
        <el-dropdown>
          <qzf-button jz="false" button_code="zb_zz_gd" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dya4h" @click="printing()" type="success" size="small">
                <el-icon><Printer /></el-icon> <span  >打印</span>
              </qzf-button></el-dropdown-item> -->
              <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dc" @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span  >导出</span>
              </qzf-button></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="tableDl" v-for="(item,index) in list" :key="index">
      <el-table stripe :height="contentStyleObj" :data="item.Vouchers"  :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :key="tableKey"  border fit highlight-current-row @sort-change="sortChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="账期" align="center" width="110">
          <template #default="{row}">
            <span>{{ row.period }}</span>
          </template>
        </el-table-column>
        <el-table-column label="日期" align="center" width="110">
          <template #default="{row}">
            <span v-if="row.voucherDate">{{$parseTime(row.voucherDate, "{y}-{m}-{d}")}}</span>
          </template>
        </el-table-column>
        <el-table-column label="凭证号码" align="center" width="200">
          <template #default="{row}">
            <span>{{ row.voucherNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="摘要" align="center" width="260">
          <template #default="{row}">
            <span>{{ row.summary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="贷方" align="center">
          <el-table-column v-for="(subject,index2) in item.subjectLower" :key="index2" prop="periodInt" :label="subject.subjectName" min-width="120" align="center">
            <el-table-column prop="periodInt" :label="subject.type == 1?subject.periodInt:subject.periodOut" min-width="120" align="center">
            <template #default="scope">
              <span v-if="subject.subjectId == scope.row.subjectId && item.big.type == 1">{{scope.row.inAmount }}</span>
              <span v-if="subject.subjectId == scope.row.subjectId && item.big.type != 1">{{scope.row.outAmount }}</span>
            </template>
          </el-table-column>
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column :label="item.big.type == 0?'贷方':'借方'" align="center">
          <el-table-column v-for="(subject,index2) in item.subjectLower" :key="index2" prop="periodInt" :label="subject.subjectName" min-width="120" align="center">
            <el-table-column prop="periodInt" :label="subject.type == 1?subject.periodInt:subject.periodOut" min-width="120" align="center">
            <template #default="scope">
              <span v-if="subject.subjectId == scope.row.subjectId && item.big.type == 1">{{scope.row.inAmount }}</span>
              <span v-if="subject.subjectId == scope.row.subjectId && item.big.type != 1">{{scope.row.outAmount }}</span>
            </template>
          </el-table-column>
          </el-table-column>
        </el-table-column> -->

        <!-- <el-table-column :label="item.big.type == 0?'借方':'贷方'" align="center" width="200">
          <template #default="{row}">
            <span>{{row.amount }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="借方" align="center" width="200">
          <template #default="{row}">
            <span>{{row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="余额" align="center" width="200">
          <template #default="{row}">
            <span>{{row.endAmount}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <exportFile ref="exportFile" name="book_multi_column" :listQuery1="this.listQuery"></exportFile>

  </div>
</template>

<script>
import { multiColumnList } from "@/api/book.js"
import exportFile from "./exportFile.vue"

export default {
  name:'detailAccount',
  components: {
    exportFile,
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      listLoading: false,
      list: [{}],
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        subjectCode: '',
        level: 1,
        rq:[]
      },
      subjectList: [
        {name:"制造费用",code:'4101'},
        {name:"生产成本",code:'4001'},
        {name:"劳务成本",code:'4002'},
        {name:"研发支出",code:'4301'},
        {name:"税金及附加",code:'5403'},
        {name:"主营业务成本",code:'5401'},
        {name:"其他业务成本",code:'5402'},
        {name:"主营业务收入",code:'5001'},
        {name:"其他业务收入",code:'5051'},
        {name:"销售费用",code:'5601'},
        {name:"管理费用",code:'5602'},
        {name:"财务费用",code:'5603'},
      ],
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(270)
    this.listQuery.subjectCode = '5602'
  },
  methods: {
    getList(){
      multiColumnList(this.listQuery).then(res=>{
        let list = res.data.data.info
        list.map(item=>{
          item.Vouchers.unshift({
            inAmount:0,
            outAmount:0,
            period: item.big.period,
            summary: "期初余额",
            endAmount: item.big.type == 1?item.big.periodBeginIn:item.big.periodBeginOut,
          })
          let amount = item.big.periodBeginIn
          if(item.big.type != 1){
            amount = item.big.periodBeginOut
          }
          item.Vouchers.map(v=>{
            if(item.big.type == 1){
              amount = (amount*1 + v.inAmount*1).toFixed(2)
              v.endAmount = amount
            }else{
              amount = (amount*1 + v.outAmount*1).toFixed(2)
              v.endAmount = amount
            }
          })

          
          if(item.big.type == 1){
            amount = (amount*1 - item.big.periodOut*1).toFixed(2)
          }else{
            amount = (amount*1 - item.big.periodInt*1).toFixed(2)
          }
          item.Vouchers.push({
            inAmount:0,
            outAmount:0,
            period: item.big.period,
            summary: this.getText(item.big.subjectCode),
            amount: item.big.type == 1?item.big.periodOut:item.big.periodInt,
            endAmount: amount,
          })
        })
        this.list = list
      })
    },
    getText(code){
      if(code == '4101'){//制造费用
        return "转入生产成本"
      }else if(code == '4001'){//生产成本
        return "转入库存商品"
      }else if(code == '4002'){//劳务成本
        return "转入主营业务成本"
      }else if(code == '5403' || code == '5602' || code == '5603' || code == '5601' || code == '5401' || code == '5402' || code == '5001' || code == '5051'){//管理费用 等损益科目
        return "转入本年利润"
      }else if(code == '4301'){//研发支出
        return "转入无形资产"
      }
    },
    daochu(){
      this.$refs.exportFile.init()
    },
  }
}
</script>

<style lang="scss" scoped>
.app-container{
  h5{
    font-size: 18px;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.top_btns {
   .left_box{
    float: left;
    width: 76%;
  }
  .right_box {
    float: right;
    width: 20%;
    text-align: right;
  }
  .time_box{
    float: left;
  }
  .bottom_btns{
    float: left;
    // margin-left: 20px;
  }
}
.tableDl {
  margin-top: 15px;
}
</style>